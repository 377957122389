import * as rive from "@rive-app/canvas";

export default function riveShowcase() {
    return {
        init() {
            const canvasElement = document.getElementById('canvas');

            if (canvasElement) {
                // Create a new Rive instance with the profile animation
                const r = new rive.Rive({
                    src: '/animations/rive-showcase/profile.riv',
                    canvas: canvasElement,
                    autoplay: true,
                    stateMachines: ["State Machine 1"], // Adjust based on actual state machine name in your Rive file
                    onLoad: () => {
                        // Resize the canvas to fit the drawing surface
                        r.resizeDrawingSurfaceToCanvas();

                        // Store the Rive instance if needed for future interactions
                        this.riveInstance = r;
                    },
                });
            } else {
                console.error('Canvas element with ID "canvas" not found.');
            }
        },

        // Add any methods for interacting with the Rive animation here
        // For example:
        triggerAnimation(inputName) {
            if (this.riveInstance) {
                const inputs = this.riveInstance.stateMachineInputs("State Machine 1");
                const trigger = inputs.find(input => input.name === inputName);
                if (trigger) trigger.fire();
            }
        }
    };
}
